import { Component, OnDestroy, ElementRef  } from '@angular/core';
import { AmvaraVideoComponent } from '../amvara-video/amvara-video.component';
import { NgDialogAnimationService } from "ng-dialog-animation";
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.sass'],
})
export class AboutComponent { 
  mobileOrDesktop: boolean = false;
  amvaraVideosClick: boolean = false;
  private mobileQueryListener: () => void;
  private mobileQuery: MediaQueryList;
  rotationAngle = 0;
  slideLeft: boolean = false;

  constructor(private dialog: NgDialogAnimationService, private breakpointObserver: BreakpointObserver, private mediaMatcher: MediaMatcher, private elementRef: ElementRef
  ) {
    // Tiene media:, matches:(true/false) etc 
    this.mobileQuery = this.mediaMatcher.matchMedia('(max-width: 600px)');

    this.mobileQueryListener = () => {
      if (this.mobileQuery.matches) {
        // Reinicia la página si cambias a vista móvil
        location.reload();
      } else {
        // Reinicia la página si cambias a vista de escritorio
        location.reload();
      }
    };
    // Contacta con el css para hacer 
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);
    this.mobileOrDesktop = this.mobileQuery.matches;
  }

  // Go to..  scroll
  onPanelOpened(): void {
    setTimeout(() => {
      this.scrollToElement('goToPos');
    }, 200);
  }
  
  scrollToElement(elementId: string): void {
    const element = document.getElementById(elementId);
    if(this.amvaraVideosClick){
      const yOffset = -60;
      const y = element.getBoundingClientRect().top + scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  openDialog(event: MouseEvent): void {
    // If dispositive is movile(handset)
    if(this.breakpointObserver.isMatched(Breakpoints.Handset)){
      // Rotate the image of amvaras videos link
      if (this.rotationAngle == 0) {
        this.rotationAngle += 90;
      } else {
        this.rotationAngle = 0;
      }
      this.amvaraVideosClick = !this.amvaraVideosClick;
    }
    else{
      // If dispositive is desktop
      this.slideLeft = true;
      const dialogRef = this.dialog.open(AmvaraVideoComponent, {
        height: '100vh',
        width: '60vw',
        position: {
          right: '0',
          top: '0'
        },
        backdropClass: 'backdrop',
        animation:{to:"left"},
      });
    
      dialogRef.afterClosed().subscribe(result => {
        setTimeout(() => {
          this.slideLeft = false;
        }, 300);
      });
    }
  }

  ngOnInit(): void {
    this.setArrowContainerHeight();
    window.addEventListener("resize", this.setArrowContainerHeight);
    // if(this.breakpointObserver.isMatched(Breakpoints.Handset)){
     
    //   this.amvaraVideosClick = false;
    //   console.log("Es falso: " + this.amvaraVideosClick);
    //   this.openDialog(null)
    // }
  }

  setArrowContainerHeight(): void {
    const arrowContainer : HTMLElement = document.querySelector(".about__content__row__container__mobile");
    const textElement = document.querySelector(".about__content__row__text__mobile");
    if (arrowContainer && textElement) {
      const textHeight = textElement.getClientRects()[0].height;
      arrowContainer.style.height = textHeight + "px";
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
  }

}