import { trigger, animate, style, group, query, transition } from '@angular/animations';

export const routerTransition = trigger('routerTransition', [
    transition('2 => 4, 2 => 5, 2 => 6, 2 => 7, 10 => *, 8 => *, 9 => 3, 2=>12, 2=>11, 3=>12, 3=>11, 3=>4, 3=>5, 3=>6, 3=>7', [
        query(':enter, :leave', style({position: 'fixed', width: '100%'}), { optional: true }),
        group([
            query(':enter', [
                style({ transform: 'translateX(100%)' }),
                animate('1s ease-in-out', style({ transform: 'translateX(0%)' }))
              ], { optional: true }),
              query(':leave', [
                style({ transform: 'translateX(0%)' }),
                animate('1s ease-in-out', style({ transform: 'translateX(-100%)' }))
              ], { optional: true }),
        ])
    ]),
    transition('2 => 8, 2 => 9, 4 => 2, 7 => 3, 5 => 2, 6 => 2, 7 => 2, *=>10, 4 => 3, 5 => 3, 6 => 3, 11=>2, 12=>2, 12=>3, 11=>3, * =>8', [
        query(':enter, :leave', style({position: 'fixed', width: '100%'}), { optional: true }),
        group([
            query(':enter', [
                style({ transform: 'translateX(-100%)' }),
                animate('1s ease-in-out', style({ transform: 'translateX(0%)' }))
              ], { optional: true }),
              query(':leave', [
                style({ transform: 'translateX(0%)' }),
                animate('1s ease-in-out', style({ transform: 'translateX(100%)' }))
              ], { optional: true }),
        ])
    ]),
    transition('8 => 2, 9 => 2', [
        query(':enter, :leave', style({position: 'fixed', width: '100%'}), { optional: true }),
        group([
            query(':enter', [
                style({ transform: 'translateX(100%)' }),
                animate('1s ease-in-out', style({ transform: 'translateX(0%)' }))
            ], { optional: true }),
            query(':leave', [
                style({ transform: 'translateX(0%)' }),
                animate('1s ease-in-out', style({ transform: 'translateX(-100%)' }))
            ], { optional: true }),
        ])
    ]),
    transition('6=>12, 5=>11, 12=>6, 11=>5', [
        query(':enter, :leave', style({position: 'fixed', width: '100%'}), { optional: true }),
        group([
            query(':enter', [
                style({ transform: 'rotate(-50deg) translateX(-120%)'  }),
                animate('1s ease-in-out', style({ transform: 'rotate(0deg) translateX(0%)' }))
              ], { optional: true }),
              query(':leave', [
                style({ transform: 'rotate(0deg) translateX(0%)' }),
                animate('1s ease-in-out', style({ transform: 'rotate(50deg) translateX(120%)' }))
              ], { optional: true }),
        ])
    ]),
    transition(':decrement', [
        query(':enter, :leave', style({position: 'fixed', width: '100%'}), { optional: true }),
        group([
            query(':enter', [
                style({ transform: 'translateY(-100%)' }),
                animate('1s ease-in-out', style({ transform: 'translateY(0%)' }))
              ], { optional: true }),
              query(':leave', [
                style({ transform: 'translateY(0%)' }),
                animate('1s ease-in-out', style({ transform: 'translateY(100%)' }))
              ], { optional: true }),
        ])
    ]),
    transition(':increment', [
        query(':enter, :leave', style({position: 'fixed', width: '100%'}), { optional: true }),
        group([
            query(':enter', [
                style({ transform: 'translateY(110%)' }),
                animate('1s ease-in-out', style({ transform: 'translateY(0%)' }))
              ], { optional: true }),
              query(':leave', [
                style({ transform: 'translateY(0%)' }),
                animate('1s ease-in-out', style({ transform: 'translateY(-105%)' }))
              ], { optional: true }),
        ])
    ])
]);
export const routerTransitionStars = trigger('routerTransitionStars', [
    transition(':increment', [
        query(':enter, :leave', style({position: 'absolute', width: '100%', top: '25%', left: '0%'}), { optional: true }),
        group([
            query(':enter', [
                style({  opacity: 0 }),
                animate('0.5s ease-in-out', style({ opacity: 1 }))
              ], { optional: true }),
              query(':leave', [
                style({ opacity: 1 }),
                animate('0.5s ease-in-out', style({ opacity: 0  }))
              ], { optional: true }),
        ])
    ]),
    transition(':decrement', [
        query(':enter, :leave', style({position: 'absolute', width: '100%', top: '25%', left: '0%'}), { optional: true }),
        group([
            query(':enter', [
                style({  opacity: 0 }),
                animate('0.5s ease-in-out', style({ opacity: 1 }))
              ], { optional: true }),
              query(':leave', [
                style({ opacity: 1 }),
                animate('0.5s ease-in-out', style({ opacity: 0  }))
              ], { optional: true }),
        ])
    ])
]);

// Animation to open and close Legal content

/* export const legal_animation = trigger('animateIt', [
    transition('void => *', [
        style({transform: 'translateY(100%)', opacity: 0}),
        animate('1s ease-in-out', style({transform: 'translateY(0)', opacity: 1}))
    ]),
    transition('* => void', [
        style({transform: 'translateY(0)', opacity: 1}),
        animate('0.5s ease-in-out', style({transform: 'translateY(100%)', opacity: 0}))
    ]),
]) */
