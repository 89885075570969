import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalComponent {

  constructor(
    public dialogRef: MatDialogRef<LegalComponent>
  ) { }

  onNoClick() {
    this.dialogRef.close();
  }
}
