import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, NgZone } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';
import { Employee } from '../employees/employees';
import { EmployeesService } from '../employees/employees.service';

@Component({
  selector: 'app-employee-information',
  templateUrl: './employee-information.component.html',
  styleUrls: ['./employee-information.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeInformationComponent implements OnInit, OnDestroy {
  allEmployees: Employee[] = [];
  employee: Employee;
  animationState: string;
  id: number;
  mobile: boolean;
  constructor(
    private ref: ChangeDetectorRef,
    private employeesService: EmployeesService,
    private route: ActivatedRoute,
    private router: Router,
    public zone: NgZone
  ) {
    this.router.events.subscribe(path => {
      this.getEmployee();
    });
  }

  ngOnDestroy() {
    this.ref.detach();
  }

  ngOnInit() {
    if (window.screen.width <= 900) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    this.setEmployees();
    this.getEmployee();
    this.idUpdate();
  }
  idUpdate(): void {
    let urlArray = this.router.routerState.snapshot.url.split("/");
    this.id = parseInt(urlArray[urlArray.length-1]);
  }
  setEmployees(): void {
    this.allEmployees = this.employeesService.getEmployees();
  }
  getEmployee(): void {
    if(window.screen.width <= 900){
      this.mobile = true;
    }else{
      this.mobile = false;
    }
    this.idUpdate();
    const id: number = parseInt(this.route.snapshot.paramMap.get('id'));
    if(id >= 1){
      this.employee = Object.assign({},this.employeesService.getEmployee(id));
      if (!this.ref['destroyed']) this.ref.detectChanges()
    }
  }

  nextEmployee(id){
    if(id >= this.allEmployees.length-1){
      id = 0;
    }
    if(window.screen.width <= 900){
      this.mobile = true;
    }else{
      this.mobile = false;
    }
    this.id = id + 1;
    //console.log(id, this.id)
    this.zone.run(() => { this.router.navigate(['/who-we-are', this.id]) });
  }

  previouseEmployee(id){
    if(id <= 0){
      id = this.allEmployees.length + 1;
    }
    if(window.screen.width <= 900){
      this.mobile = true;
    }else{
      this.mobile = false;
    }
    this.id = id - 1;
    this.zone.run(() => { this.router.navigate(['/who-we-are', this.id]) });
  }


}
