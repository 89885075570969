import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {differenceInYears, differenceInMonths, differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds, subYears, subDays, subMonths, subHours, subMinutes, subSeconds} from 'date-fns';
import { interval, Subscription } from 'rxjs';

let dateFns = {
  "differenceInYears": differenceInYears,
  "differenceInMonths": differenceInMonths,
  "differenceInDays": differenceInDays,
  "differenceInHours": differenceInHours,
  "differenceInMinutes": differenceInMinutes,
  "differenceInSeconds": differenceInSeconds,
  "subYears": subYears,
  "subMonths": subMonths,
  "subDays": subDays,
  "subHours": subHours,
  "subMinutes": subMinutes,
  "subSeconds": subSeconds
};


let num_years, num_days, num_horas, num_mins, num_months, num_secs;
let run = false;
let circleTwo, changeMinutes;
@Component({
  selector: 'app-amvara-clock',
  templateUrl: './amvara-clock.component.html',
  styleUrls: ['./amvara-clock.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmvaraClockComponent implements OnInit, OnDestroy {

  constructor(private router: Router) {
    this.router.events.subscribe(path => {
      if (path instanceof NavigationEnd) {
        if (path.url == '/amvara-clock') {
          run = true;
        } else {
          run = false;
        }
      }
    });
  }

  humanizeFutureToNow(fDate) {
    let result = {}, now = new Date()
    let parts = ['year', 'month', 'day', 'hour', 'minute', 'second']
    parts.forEach((p, i) => {
      let uP = p.charAt(0).toUpperCase() + p.slice(1)
      let t:number = dateFns[`differenceIn${uP}s`](fDate, now);
      if (t) {
        const abs = Math.abs(t) == undefined ? 0 : Math.abs(t);
        result[p] = (abs) < 10 ? `0${abs}` : abs;
        if (i < parts.length)
          fDate = dateFns[`sub${uP}s`](fDate, t);
      }else{
        result[p] = "00";
      }
    })
    return result;
  }

  amvaraTime: Subscription


  ngOnInit() {
    if(!run){
      return;
    }

    this.amvaraTime = interval(1000).subscribe(_ => {
      let diff = this.humanizeFutureToNow(new Date('2012-01-04 00:00:00'));
      num_years = diff['year'];
      num_months = diff['month'];
      num_days = diff['day'];
      num_horas = diff['hour'];
      num_mins = diff['minute'];
      num_secs = diff['second'];

      document.getElementsByClassName("amvara-clock__since__years")[0].innerHTML = num_years;
      document.getElementsByClassName("amvara-clock__since__months")[0].innerHTML = num_months;
      document.getElementsByClassName("amvara-clock__since__days")[0].innerHTML = num_days;
      document.getElementsByClassName("amvara-clock__since__hours")[0].innerHTML = num_horas;
      document.getElementsByClassName("amvara-clock__since__minutes")[0].innerHTML = num_mins;
      document.getElementsByClassName("amvara-clock__since__seconds")[0].innerHTML = num_secs;
    });

    let d = new Date();
    function toRoman(num) {
      let result = '';
      let decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
      let roman = ["M", "CM","D","CD","C", "XC", "L", "XL", "X","IX","V","IV","I"];
      for (let i = 0;i<=decimal.length;i++) {
        while (num%decimal[i] < num) {
          result += roman[i];
          num -= decimal[i];
        }
      }
      return result;
    }

    function createDates(daysOfService){
      let d = new Date();
      let f = new Date(d.getFullYear(), d.getMonth()+1, 0).getDate();
      let days = document.getElementById("daysg");
      while(days.hasChildNodes()) {
        days.removeChild(days.lastChild);
      }
      for(let i = 0; i < f; i++){
        let classes = "ALines";
        let path = document.createElementNS("http://www.w3.org/2000/svg","path");
        path.setAttribute("d", "M490 130 L500 105 L510 130");
        if(i < daysOfService){
          classes = classes + " active";
        }else if(i == daysOfService){
          classes = classes + " blinki";
        }
        path.setAttribute("class", classes);
        path.setAttribute("transform", "rotate("+ (360/f)*(i-1) +" 500 500)");
        days.appendChild(path);
        days.setAttribute("transform", "rotate("+ -(360/f)*i +" 500 500)");
      }
    }
    function createMonths(monthsOfService){
      let rad = 500;
      let aRad = 30*Math.PI/180;
      let z = Math.sqrt( 2*rad*rad - (2*rad*rad*Math.cos(aRad)))
      let x = rad*Math.sin(aRad);
      let y = Math.sqrt((z*z) - (x*x));
      x = x + rad;
      let colors = ['#f6f5f2', '#ecebe8'];
      let months = document.getElementById("months");
      while(months.hasChildNodes()) {
        months.removeChild(months.lastChild);
      }

      for(let i = 0; i<12; i++){
        let circle = document.createElementNS("http://www.w3.org/2000/svg","circle");
        let path = document.createElementNS("http://www.w3.org/2000/svg","path");

        if (i <= monthsOfService) {                  // Selects completed and in progress months //
          path.setAttribute("fill", colors[i % 2]); // Modifies completed and ongoing months background colour //
        } else {                                   // Else condition for every other month //
          path.setAttribute("fill", 'none');
        }

        path.setAttribute("d", "M500 500 L500 150 A 350, 350 1 0, 1 " + x + " " + y + " z");
        path.setAttribute("transform", "rotate("+ ((30*i)-15) +" 500 500)");
        months.appendChild(path);
        circle.setAttribute("cx", "500");
        circle.setAttribute("cy", "275");
        circle.setAttribute("r", "18");
        circle.setAttribute("class", "monthscircle");
        circle.setAttribute("fill", i < monthsOfService ? "#002f5e" : 'none');
        circle.setAttribute("transform", "rotate("+ (30*i) +" 500 500)");
        months.appendChild(circle);
      }
    }

    let totalCircles = 360/59;
    function r(el, deg){
      el.setAttribute("transform", "rotate("+ deg +" 500 500)");
    }
    for(let i = 0; i < 24; i++){
      let hoursElement = document.getElementById("hours");
      let rad = 220 + i*7;
      let circle = document.createElementNS("http://www.w3.org/2000/svg", 'circle');
      circle.setAttribute("cx", "500");
      circle.setAttribute("cy", "500");
      if(i < d.getHours()){
        circle.setAttribute("class", "hoursCircle passed");
      }else if(i == d.getHours()){
        circle.setAttribute("class", "hoursCircle blink");
      }else{
        circle.setAttribute("class", "hoursCircle");
      }
      circle.setAttribute("r", rad.toString());
      hoursElement.appendChild(circle);
    }
    for(let i = 0; i < d.getMinutes(); i++){
      let mins = i;
      let minutesElement = document.getElementById("minutes");
      let circle = document.createElementNS("http://www.w3.org/2000/svg", 'circle');
      circle.setAttribute("cx", "500");
      circle.setAttribute("cy", "330");
      circle.setAttribute("r", "8");
      circle.setAttribute("class", "min");
      circle.setAttribute("transform", "rotate("+ -((totalCircles*mins)+totalCircles) +" 500 500)");
      minutesElement.appendChild(circle);
    }
    let msCircle = document.getElementById("msCircle");
    let secondLine = document.getElementById("secondLine");
    setInterval(function(){
      let d = new Date();
      r(msCircle, 0.36*d.getMilliseconds());
      r(secondLine, 6*d.getSeconds());
    }, 1);

    changeMinutes = setInterval(function(){
      let d = new Date();
      let mins = (d.getSeconds()/60) + d.getMinutes();
      r(document.querySelector("#minutes"), totalCircles*mins);

    //  document.getElementById("fillin").setAttribute("transform", "translate(0, "+ ((65-d.getSeconds())*17)/65 +")");

      if(d.getSeconds() == 0){
        if(d.getMinutes() != 0){
          let cyPosition = 371;
          let cyAddition = 330/1000;
          let minutesElement = document.getElementById("minutes");
          let circle = document.createElementNS("http://www.w3.org/2000/svg", 'circle');
          circle.setAttribute("cx", "500");
          circle.setAttribute("cy", cyPosition.toString());
          circle.setAttribute("r", "8");
          circle.setAttribute("class", "min");
          circle.setAttribute("transform", "rotate("+ -(totalCircles*mins) +" 500 500)");
          minutesElement.appendChild(circle);
          let animateMinute = setInterval(function(){
            if(cyPosition > 330){
              cyPosition = cyPosition - cyAddition;
              circle.setAttribute("cy", cyPosition.toString());
            }else{
              clearInterval(animateMinute);
            }
          }, 1);
        }else{
          document.getElementsByClassName("hoursCircle")[d.getHours()-1].setAttribute("class", "hoursCircle passed");
          document.getElementsByClassName("hoursCircle")[d.getHours()-1].classList.remove("blinking");
          document.getElementsByClassName("hoursCircle")[d.getHours()].setAttribute("class", "hoursCircle blink");
          let minutesElement = document.getElementById("minutes");
          while(minutesElement.hasChildNodes()) {
            minutesElement.removeChild(minutesElement.lastChild);
          }
        }
      }
      if(d.getHours() == 0){
        for(let i=0; i < 24; i++){
          document.getElementsByClassName("hoursCircle")[i].classList.remove("passed");
        }
      }
    }, 1000);
	let currentDate = null;
    circleTwo = setInterval(function(){
		if (currentDate != new Date().getDate() || currentDate == null){
			document.getElementById("yearsText").innerHTML = toRoman(num_years);
			createMonths(num_months);
			createDates(num_days-1);

			currentDate = new Date().getDate();
		}
    }, 1000);

  }
  ngOnDestroy(){
    clearInterval(circleTwo);
    clearInterval(changeMinutes);
    if (this.amvaraTime) this.amvaraTime.unsubscribe();
  }
}
