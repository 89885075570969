import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-amvara-video',
  templateUrl: './amvara-video.component.html',
  styleUrls: ['./amvara-video.component.sass']
})
export class AmvaraVideoComponent implements OnInit {
  mobileOrDesktop: boolean = false;
  private mobileQueryListener: () => void;
  private mobileQuery: MediaQueryList;

  constructor( private breakpointObserver: BreakpointObserver, private mediaMatcher: MediaMatcher) { 
    this.mobileQuery = this.mediaMatcher.matchMedia('(max-width: 600px)');
    this.mobileOrDesktop = this.mobileQuery.matches;
  }
  
  showContent(): void {
    if(this.breakpointObserver.isMatched(Breakpoints.Handset)){
      this.mobileOrDesktop = !this.mobileOrDesktop;
    }
  }

  ngOnInit(): void {
    
  }
}
