import { Employee } from './employees';

export const EMPLOYEES: Employee[] = [
    {
        id: 1,
        name: 'RALF ROEBER',
        position: 'Managing Partner',
        about: ['+25 years of experience in IT industry', 'In depth knowledge of OSI level 2 to 7', 'Internet standards expert', '+10 years IBM Cognos professional', 'IBM performance star (2006)', 'IBM Champion 2021'],
        gender: 'M',
        profilePicture: '00_AMVARA_Staff_Ralf_BN.jpg'
    },
    {
        id: 2,
        name: 'Luz Maria Leon',
        position: 'Business Development',
        about: ['Business Consultant'],
        gender: 'F',
        profilePicture: '00_AMVARA_Staff_LUZMA_BN.jpg'
    },
    {
        id: 3,
        name: 'LLUIS TORROJA',
        position: 'Art Director',
        about: ['Advanced Graphic Design Technician', 'Advanced Image Technician', 'Degree in Fine Arts', 'Master’s Degree in Teaching', '+10 years of experience in image and design scene'],
        gender: 'M',
        profilePicture: '00_AMVARA_Staff_Luis_BN.jpg'
    },
    {
        id: 4,
        name: 'Nagesh Narasappa',
        position: 'Full Stack & Service Management',
        about: ['+12 year of experience in IT industry', 
        'PMP Certified (2024)',
        'Service Management',
        'Infrastructure consulting',
        'Operations Management'],
        gender: 'M',
        profilePicture: '00_AMVARA_Staff_nagesh_BN.jpg'
    },
    {
        id: 5,
        name: 'Redouan Nati',
        position: 'Web Developer',
        about: ['Skills in web app creation, Visual design'],
        gender: 'M',
        profilePicture: 'avatar_anonim.png'
    },
    {
        id: 6,
        name: 'Anand Kushwaha',
        position: 'Backend Developer',
        about: ['Experienced in API Development'],
        gender: 'M',
        profilePicture: '00_AMVARA_Staff_Anand_BN.png'
        
    },
    {
        id: 7,
        name: 'Shyam Kumar',
        position: 'Service Management',
        about: ['+22 years of IT industry experience', 
        'Service Management',
        'Infrastructure consulting',
        'Operations Management'],
        gender: 'M',
        profilePicture: '00_AMVARA_Staff_Shyam_BN.png'
    },
    {
        id: 8,
        name: 'Blacky',
        position: 'Watchdog',
        about: ['Full time guardian'],
        gender: 'D',
        profilePicture: '00_AMVARA_Staff_Blacky.jpg'
    },
    
];
