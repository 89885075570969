import { Component, ChangeDetectionStrategy } from '@angular/core';


@Component({
  selector: 'app-page4day',
  templateUrl: './page4day.component.html',
  styleUrls: ['./page4day.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class Page4dayComponent { }
