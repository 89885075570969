import { Injectable } from '@angular/core';

import { Employee } from './employees';
import { EMPLOYEES } from './employees-data';

@Injectable()
export class EmployeesService {
    constructor() { }

    getEmployees(): Employee[] {
        return EMPLOYEES;
    }
    getEmployee(id: number): Employee {
        return EMPLOYEES.find(employee => employee.id === id);
    }
}