import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';
import { Employee } from '../employees/employees';
import { EmployeesService } from '../employees/employees.service';
import { trigger, keyframes, animate, transition } from '@angular/animations';
import * as kf from '../keyframes/keyframes';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.sass'],
  animations: [
    trigger('cardAnimator', [
      transition('* => slideOutLeft', animate(500, keyframes(kf.slideOutLeft))),
      transition('* => slideOutRight', animate(500, keyframes(kf.slideOutRight))),
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class WhoWeAreComponent {

  /** Holds the array of all amployees */
  allEmployees: Employee[] = [];
  
  /** Holds the current selected employee opject */
  employee$: BehaviorSubject<Employee> = new BehaviorSubject<Employee>(null)

  /** Holds the animation system when changing between employees */
  animationState$ = new BehaviorSubject<string>(null);

  /** Holds the observable to know if we are in mobile view or not */
  mobileSubject = new BehaviorSubject<boolean>(window.screen.width <= 900)

  /** Value comes from mobileSubject but view is not updated when there's a boolean change */
  mobile$ = this.mobileSubject.pipe(
    distinctUntilChanged()
  )

  constructor(
    private employeesService: EmployeesService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.allEmployees = this.employeesService.getEmployees();
    this.route.paramMap.subscribe(params => {
      // Grab employee id
      let id = +params.get('id');
      // Make sure ID exists in array, if not, defaults to first
      id = this.allEmployees.some(e => e.id === id) ? id : 1;
      // Send employee info to view observable
      this.employee$.next(this.employeesService.getEmployee(id));
    });
  }

  /**
   * Listen for window resize event changes
   * Then recalculate mobile observable
   */
  @HostListener('window:resize') onResize() {
    this.mobileSubject.next(window.screen.width <= 900);
  }

  /**
   * Executed whenever the user swipes
   * FIXME: Not working
   */
  startAnimation(state) {
    if (!this.animationState$.getValue()) {
      this.animationState$.next(state);
      if (state == 'slideOutLeft') {
        this.nextEmployee();
      } else {
        this.previousEmployee();
      }
    }    
  }

  /**
   * Executed whenever the swipe ends
   */
  resetAnimationState() {
    this.animationState$.next('');
  }

  /**
   * Go to next employee
   */
  nextEmployee() {
    let nextId = this.employee$.getValue().id + 1;
    if (nextId > this.allEmployees.length) {
      nextId = 1;
    }
    this.router.navigate(['/who-we-are', nextId]);
  }

  /**
   * Go to previous employee
   */
  previousEmployee() {
    let nextId = this.employee$.getValue().id - 1;
    if (nextId === 0) {
      nextId = this.allEmployees.length;
    }
    this.router.navigate(['/who-we-are', nextId]);
  }
}