import { style } from '@angular/animations';

export const slideOutLeft = [
    style({ opacity: 1, offset: 0}),
    style({ opacity: 0, offset: 1}),
];

export const slideOutRight = [
    style({ opacity: 1, offset: 0}),
    style({ opacity: 0, offset: 1}),
];
