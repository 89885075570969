import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { Page3Component} from './components/page3/page3.component';
import { Page4Component} from './components/page4/page4.component';
import { LegalComponent} from './dialogs/legal/legal.component';
import { IbmcognosComponent} from './components/ibmcognos/ibmcognos.component';
import { DesignComponent} from './components/design/design.component';
import { CodeingComponent} from './components/codeing/codeing.component';
import { EnterpriseApplicationComponent } from './components/enterprise-application/enterprise-application.component';
import { Page4dayComponent} from './components/page4day/page4day.component';
import { WhoDoWeWorkForComponent } from './components/who-do-we-work-for/who-do-we-work-for.component';
import { WhoDoWeWorkForDayComponent } from './components/who-do-we-work-for-day/who-do-we-work-for-day.component';
import { WhoWeAreComponent } from './components/who-we-are/who-we-are.component';
import { ContactComponent } from './components/contact/contact.component';
import { AmvaraClockComponent } from './components/amvara-clock/amvara-clock.component';
import { AmvaraVideoComponent } from './components/amvara-video/amvara-video.component';
import { AboutNightComponent } from './components/about-night/about-night.component';


const routes: Routes = [
    { path: '', pathMatch: 'full', component: HomepageComponent, data: { navcolor: 'dark', title: 'Welcome to Amvara', state: '/', page: 'home', index: 1 } },
    //{ path: 'about',component: AboutwrapperComponent ,data: { navcolor: 'dark', title: 'About | Amvara',  page: 'about', index: 2}, children:[
    { path: 'about',pathMatch: 'full' , component: AboutComponent, data: { navcolor: 'dark', title: 'About | Amvara',  page: 'about', index: 2 }  },
    { path: 'about-night', component: AboutNightComponent, data: { navcolor: 'both', title: 'What is code? | Amvara', state: '/about-night', index:8} },
    { path: 'how-we-can-help-you', component: Page3Component, data: { navcolor: "light", title: 'How we can help you? | Amvara', state: '/how-we-can-help-you', index: 4 } },
    { path: 'what-do-we-do', component: Page4Component, data: { navcolor: "light", title: 'What do we do? | Amvara', state: '/what-do-we-do', index: 5  }, children: [
        { path: '', redirectTo: 'enterprise-application', pathMatch: 'full' },
        { path: 'enterprise-application', component: EnterpriseApplicationComponent, data: { navcolor: "light", title: 'EnterpriseApplication | Amvara', state: '/enterprise-application', stars:1 } },
        { path: 'ibmcognos', component: IbmcognosComponent, data: { navcolor: "light", title: 'IBM Cognos | Amvara', state: '/ibmcognos', stars:2 } },
        { path: 'design', component: DesignComponent, data: { navcolor: "light", title: 'Design | Amvara', state: '/design', stars:3 } },
        { path: 'codeing', component: CodeingComponent, data: { navcolor: "light", title: 'Coding | Amvara', state: '/coding', stars:4 } },
    ] },
    { path: 'what-do-we-do-day', component: Page4dayComponent, data: { navcolor: "dark", title: 'What do we do? | Amvara', state: '/what-do-we-do-day', index:11 } },
    { path: 'who-do-we-work-for', component: WhoDoWeWorkForComponent, data: { navcolor: "light", title: 'Who do we work for? | Amvara', state: '/who-do-we-work-for',  index: 6  } },
    { path: 'who-do-we-work-for-day', component: WhoDoWeWorkForDayComponent, data: { navcolor: "dark", title: 'Who do we work for? | Amvara', state: '/who-do-we-work-for-day', index: 12} },
    { path: 'who-we-are/:id', component: WhoWeAreComponent, data: { navcolor: "dark", title: 'Who we are? | Amvara', state: '/who-we-are', index: 7 } },
    { path: 'amvara-clock', component: AmvaraClockComponent, data: { navcolor: "dark", title: 'Amvara\'s clock | Amvara', state: '/amvara-clock', index: 9  } },
    { path: 'amvara-video', component: AmvaraVideoComponent, data: { navcolor: "light", title: 'Amvara\'s video | Amvara', state: '/amvara-video', index: 13 } },
//] },
{ path: 'contact', component: ContactComponent, data: { navcolor: 'light', title: 'Contact | Amvara', state: '/contact', page: 'contact', index: 3 } },

{ path: 'legal', component: LegalComponent, data: { navcolor: "dark", title: 'Aviso legal | Amvara', state: '/legal', index: 10 } }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
