import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone, HostBinding } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { DataService } from './services/data.service';
import { Title } from '@angular/platform-browser';
import { routerTransition } from './app-router-animations';
import { browser } from '../../node_modules/protractor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { LegalComponent } from './dialogs/legal/legal.component';
declare var window: any;
var followMouse = true;
var aboutInfo = false;
var blankBG = false;
var darkBG = false;
var blueBG = false;
import titles from '../assets/titles.json';

@Component({
  selector: 'app-root',
  animations: [
    routerTransition
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})

export class AppComponent implements OnInit {
  navColor: string;
  state: number;
  lastY: number;
  last: number;
  bottom: boolean;
  position: number;
  animationdisabled = false;
  isafari: string;
  c = 0;

  changeCursor() {
    const defaultCursor = 'url("/assets/img/amvara_web_icon_bracket.cur") 16 16, url("/assets/img/Parentesis.svg") 16 16, default';
    const hashpath = window.location.hash;
    if (hashpath == "#/") {
      document.querySelectorAll(".navigation__menu > ul > a").forEach(function(elemento) {
        const e = <HTMLElement>elemento;
        e.style.cursor = "none";
      });
      followMouse = false;
    } else {
      followMouse = true;
      document.querySelectorAll(".navigation__menu > ul > a").forEach(function(elemento) {
        const e = <HTMLElement>elemento;
        e.style.cursor = defaultCursor;
      });
    }
  }

  constructor(
    private router: Router,
    public data: DataService,
    private titleService: Title,
    private ref: ChangeDetectorRef,
    private Dialog: MatDialog,
    public zone: NgZone ) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          const page = event.url.replace(/\//g, '');
          if (page) {
            this.titleService.setTitle(`Amvara - ${titles[page]}`);
          } else {
            this.titleService.setTitle('Amvara');
          }
        }
      });


  // Function to close Legal Content when choose a menu option

    /* this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd ){
        this.legal = false
      }
    }) */
    var nVer = navigator.appVersion;
var nAgt = navigator.userAgent;
var browserName  = navigator.appName;
var fullVersion  = ''+parseFloat(navigator.appVersion); 
var majorVersion = parseInt(navigator.appVersion,10);
var nameOffset,verOffset,ix;
// In Opera 15+, the true version is after "OPR/" 
if ((verOffset=nAgt.indexOf("OPR/"))!=-1) {
 browserName = "Opera";
 fullVersion = nAgt.substring(verOffset+4);
 browserName=this.isafari;
}
// In older Opera, the true version is after "Opera" or after "Version"
else if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
 browserName = "Opera";
 browserName=this.isafari;
 fullVersion = nAgt.substring(verOffset+6);
 if ((verOffset=nAgt.indexOf("Version"))!=-1) 
   fullVersion = nAgt.substring(verOffset+8);
}
// In MSIE, the true version is after "MSIE" in userAgent
else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
 browserName = "Microsoft Internet Explorer";
 fullVersion = nAgt.substring(verOffset+5);
 this.isafari="";
}
// In Chrome, the true version is after "Chrome" 
else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
 browserName = "Chrome";
 fullVersion = nAgt.substring(verOffset+7);
 this.isafari="chrome";
}
// In Safari, the true version is after "Safari" or after "Version" 
else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
 browserName = "Safari";
 browserName=this.isafari;
 fullVersion = nAgt.substring(verOffset+7);
 if ((verOffset=nAgt.indexOf("Version"))!=-1) 
   fullVersion = nAgt.substring(verOffset+8);
}
// In Firefox, the true version is after "Firefox" 
else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
 browserName = "Firefox";
 fullVersion = nAgt.substring(verOffset+8);
 browserName = this.isafari;
}

// In most other browsers, "name/version" is at the end of userAgent 
else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
          (verOffset=nAgt.lastIndexOf('/')) ) 
{
 browserName = nAgt.substring(nameOffset,verOffset);
 fullVersion = nAgt.substring(verOffset+1);
 
 if (browserName.toLowerCase()==browserName.toUpperCase()) {
  browserName = navigator.appName;
 }

}

//console.log(this.isafari)
// trim the fullVersion string at semicolon/space if present
if ((ix=fullVersion.indexOf(";"))!=-1)
   fullVersion=fullVersion.substring(0,ix);
if ((ix=fullVersion.indexOf(" "))!=-1)
   fullVersion=fullVersion.substring(0,ix);

   this.changeCursor();

majorVersion = parseInt(''+fullVersion,10);
if (isNaN(majorVersion)) {
 fullVersion  = ''+parseFloat(navigator.appVersion); 
 majorVersion = parseInt(navigator.appVersion,10);
}
    this.router.events.subscribe(path => {
      if(path instanceof NavigationEnd){

        this.changeCursor();

        if(path.url == "/about" || path.url == "/about-night"){
          followMouse = false;
          aboutInfo = false;
          blankBG = false;
          blueBG = false;
          darkBG = false;
        }else if(path.url.includes("/who-do-we-work-for-day") || path.url.includes("/who-we-are") || path.url.includes("/who-we-are/") || path.url.includes("/what-do-we-do-day") || path.url.includes("/amvara-clock" )|| path.url.includes("/legal")){
          blankBG = true;
          aboutInfo = true;
          followMouse = false;
          blueBG = false;
          darkBG = false;
        }else if(path.url.includes("/who-do-we-work-for") || path.url.includes("/how-we-can-help-you") || path.url.includes("/what-do-we-do") || path.url.includes("/what-do-we-do/ibmcognos") || path.url.includes("/what-do-we-do/codeing") || path.url.includes("/what-do-we-do/design") || path.url.includes("/what-do-we-do/enterprise-application") ){
          followMouse = false;
          aboutInfo = true;
          blankBG = false;
          blueBG = false;
          darkBG = false;
        }else if(path.url.includes("/contact")){
          blankBG = true;
          aboutInfo = true;
          followMouse = false;
          blueBG = false;
          darkBG = true
        }else{
          followMouse = true;
          aboutInfo = false;
          blankBG = false;
          darkBG = false;
        }      
      }
    });
    this.router.events
    .subscribe(() => {
        var root = this.router.routerState.snapshot.root;
        while (root) {
            if (root.children && root.children.length) {
                root = root.children[0];
            } else if (root.data && root.data["navcolor"]) {
                this.navColor = root.data["navcolor"];
                return;
            } else {
                return;
            }
        }
    });
  }

  

  ngAfterViewChecked() {
    this.ref.detectChanges();
    }

  @ViewChild('o') outlet: RouterOutlet;
  getState(outlet){
    this.state=outlet.activatedRouteData['index']
    if (this.state>7){
      this.last=this.state;
    }
    if(this.router.url.includes("/legal")){
      this.data.hidelegal=true
    }else{
      this.data.hidelegal=false
    }
    return this.state;
  }
  touchEventstart = (e):void =>{
    this.last=e.changedTouches[0].screenY
    this.position=window.pageYOffset;
  }
//Scroll SWAP
  touchEventend = (e):void =>{
    var bottom=window.innerHeight+this.position;
    var y=e.changedTouches[0].screenY
    var pages = ['/', '/about', '/contact', '/how-we-can-help-you', '/what-do-we-do', '/who-do-we-work-for', '/who-we-are'];
    var at = this.state;
    var slide;
    var pageToGo;
    if(this.last-y>=150 || y-this.last>=150){
      slide=true;
    }
    if(at>0 && at<=pages.length){
      if(at<=3 && slide==true ){
        if (this.last>y && bottom==document.body.offsetHeight && at<3){
          //pageToGo = pages[(at)];
          //this.zone.run(() => { this.router.navigate([pageToGo]) });
          }
        if(y>this.last && this.position==0 && at>1){
          //pageToGo = pages[(at-2)]
          //this.zone.run(() => {this.router.navigate([pageToGo]) });
        } 
      }else if(at>3 && slide==true){
        if(y>this.last && this.position<=0 && at>=4){
          if(at==4){
            //pageToGo=pages[(1)];
          }else{
            //pageToGo = pages[(at-2)]
          }
          //this.zone.run(() => {this.router.navigate([pageToGo]) });
        }else if(y<this.last && bottom==document.body.offsetHeight  && at<=pages.length ){
          if (at==pages.length){
            //pageToGo= pages[(2)];
          }else{
            //pageToGo = pages[(at)];
        }
        //this.zone.run(() => {this.router.navigate([pageToGo]) });
        }
      }
    }
  }
//Scroll SWAP
  changepage= (e):void => {
    var position= window.pageYOffset;
    var bottom=window.innerHeight+position;
    var pages = ['/', '/about', '/contact', '/how-we-can-help-you', '/what-do-we-do', '/who-do-we-work-for', '/who-we-are/1'];
    var at = this.state;
    var pageToGo;
    //console.log(e.deltaY)
      if(at<=3){
        if(e.deltaY < 0 && position<=0 && at>1){
          //pageToGo = pages[(at-2)]
          //console.log(at);

          //this.zone.run(() => {this.router.navigate([pageToGo]) });
        }if(e.deltaY>0 && bottom==document.body.offsetHeight && at<3){
          //pageToGo = pages[(at)];
          //this.zone.run(() => {this.router.navigate([pageToGo]) });
          //console.log(at);

      }
      }else if(at>3 && at<= pages.length){
        //console.log("entra1")
        if(e.deltaY < 0 && position<=0 && at>=4){
          //console.log("entra2")

          if(at==4){
            //pageToGo=pages[(1)];
          }else{
            //pageToGo = pages[(at-2)]
          }
          //this.zone.run(() => {this.router.navigate([pageToGo]) });
        }else if(e.deltaY>0 && bottom==document.body.offsetHeight && at<=pages.length){
          //console.log("entra3")
          //console.log(at, pages.length)
          if (at==pages.length){
            //pageToGo= pages[(2)];
          }else{
            //pageToGo = pages[(at)];
        }
        //this.zone.run(() => {this.router.navigate([pageToGo]) });
        }
        
    }

}
  ngOnInit() {
      //window.addEventListener("touchstart", this.touchEventstart, true);
      //window.addEventListener("touchend", this.touchEventend, true);

      window.addEventListener("wheel", this.changepage, true);
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    var cv = document.querySelector('.introCanvas') as any;
    var parentesisImg = document.getElementsByClassName("parentesis")[0] as any;
    // this.parImg = parentesisImg;
    cv.width = window.innerWidth;
    cv.height = window.innerHeight;
    var selected = "Home";
    var frase = "010101110110100001100101011011100010000001110100011010000110010100100000011101110110111101110010011011000110010000100000011011110110011000100000011100100110111101100010011011110111010001110011011001110110111101110110011001010111001001101110011000010111010001100101011100110010000001110100011010000110010100100000011100100111100101110100011010000110110100100000011011110110011001101111011101010111010000100000011011000110100101110110011001010010110000100000011000010110111000100000011000010110111001100001011011000111100101110100011010010110001101100001011011000010000001101101011010010110111001"; // 100100011000010110111001100100001000000110100001110101011011010110000101101110001000000110100101101110011101000110010101110010011000010110001101110100011010010110111101101110011100110110001001100101011000110110111101101101011001010010000001110100011010000110010100100000011001000110100101100110011001100110010101110010011001010110111001110100011010010110000101110100011010010110111001100111011001100110000101100011011101000110111101110010
    window.c = cv.getContext('2d');
    var distance = 100;
    var startWithThese = 300;
    var createCircle, animateNumbers;
    function pitagoras(x1, y1, x2, y2){
      var xD = x1 - x2;
      var yD = y1 - y2;
      return Math.sqrt(Math.pow(xD, 2) + Math.pow(yD, 2));
    }
    window.addEventListener('resize', function(){
      cv.width = window.innerWidth;
      cv.height = window.innerHeight;
      var totalnumbers = numbers.length;
      numbers = [];
      init(totalnumbers);
      if(followMouse == false && !aboutInfo && !blankBG){
        clearInterval(createCircle);
        makeCircle();
      }
    });
    var mX = window.innerWidth/3.3333333;
    var mY = window.innerHeight/2;

    window.addEventListener('mousemove', function(e){
      mX = e.pageX;
      mY = e.pageY;
      if(mX > 5 && mY > 5 && mX < cv.width - 5 && mY < cv.height - 5 && followMouse){
        parentesisImg.style.left = mX + "px";
        parentesisImg.style.top = mY + "px";
      }
      if(!followMouse){
        parentesisImg.style.display = "none";
      }else{
        parentesisImg.style.display = "block";
      }
      // let navigation__menu = document.querySelector(".navigation__menu > ul");
      // var top = navigation__menu.offsetParent.offsetTop+navigation__menu.offsetTop-1;
      // var left = navigation__menu.offsetParent.offsetLeft+navigation__menu.offsetLeft;
      // var leftwidth = navigation__menu.offsetWidth+left+4;
      // var topheight = navigation__menu.offsetHeight+top+1;

      // if(mY >= top && mY <= topheight && mX >= left && mX <= leftwidth){
      //   parentesisImg.style.display = "none";
      //   }else{
      //     parentesisImg.style.display = "block";
      //   }
    });
    function getEventTarget(e) {
      e = e || window.event;
      return e.target || e.srcElement; 
    }
    var numbers = [];
    class Number {
        id
        x
        y
        d
        t
        size
        op
        speed
        draw
        update
        goCenter
        constructor(x, y, id, d) {
            this.id = id;
            this.x = x;
            this.y = y;
            this.t = ((Math.floor(Math.random() * (3 - 1)) + 1) == 1) ? "0" : "1";
            this.d = d;
            if(this.d == 1){
                this.size = 12;
                this.op = 0.1;
                this.speed = (Math.floor(Math.random() * (2 - 1)) + 1) * 0.07;
            }else if(this.d == 2){
                this.size = 13;
                this.op = 0.2;
                this.speed = (Math.floor(Math.random() * (4 - 1)) + 1) * 0.06;
            }else{
                this.size = 14;
                this.op = 0.3;
                this.speed = (Math.floor(Math.random() * (10 - 1)) + 1) * 0.05;
            }
            var fsize = this.size;
            this.draw = function () {
              window.c.beginPath();
              window.c.fillStyle = "rgba(149, 165, 166,"+ this.op +")";
              window.c.font = fsize + "px Open Sans";
              window.c.textAlign = "center";
              window.c.baseline = "middle";
              window.c.fillText(this.t, x, y);
            };
            this.update = function () {
              if(followMouse){
                if(mX > 5 && mY > 5 && mX < cv.width-5 && mY < cv.height-5){
                  if (pitagoras(this.x, this.y, mX, mY) < distance) {
                      var dx = mX - x;
                      var dy = mY - y;
                      x += dx * this.speed;
                      y += dy * this.speed;
                  }else {
                      var dx = this.x - x;
                      var dy = this.y - y;
                      x += dx * this.speed;
                      y += dy * this.speed;
                  }
                }
                fsize = this.size;
                this.draw();
              }else{
                this.goCenter();
              }
            };
            this.goCenter = function(){
              var dx = cv.width/2 - x;
              var dy = cv.height/2 - y;

              x += dx * 0.5;
              y += dy * 0.5;

              if(pitagoras(x, y, cv.width/2, cv.height/2) < 20){
                var me = numbers.indexOf(this);
                numbers.splice(me, 1);
              }
              this.draw();
            };
        }
    }
    function init(total){
      for (var i = 0; i < total; i++){
        var x = Math.random() * (cv.width - 7) + 7;
        var y = Math.random() * (cv.height - 7) + 7;
        var depth = Math.floor(Math.random() * (4 - 1)) + 1;
        if(i != 0){
          for(var j = 0; j < numbers.length; j++){
            if(pitagoras(x, y, numbers[j].x, numbers[j].y) - numbers[j].size < 0){
              x = Math.random() * (cv.width - 7) + 7;
              y = Math.random() * (cv.height - 7) + 7;
              
              j = -1;
            }
          }
        }
        numbers.push(new Number(x, y, i, depth));
      }
    }
    function a(){
      window.c.fillStyle = 'rgba(249, 249, 249, .7)';
      window.c.fillRect(0,0,cv.width, cv.height);
      if(numbers.length > 0){
        animateNumbers = requestAnimationFrame(a);
      }else{
        cancelAnimationFrame(animateNumbers);
        makeCircle();
      }
      for (var i = 0; i < numbers.length; i++){
          numbers[i].update();
      }
    }
    init(startWithThese);
    a();
    function makeCircle(){
      window.c.fillStyle = 'rgba(249, 249, 249, .7)';
      window.c.fillRect(0,0,cv.width, cv.height);
      var rotate = 0;
      var radius = 17;
      var fontsize = 12;
      var rotateVal = 360/radius;
      var i = 0;
      createCircle = setInterval(function(){
        if(followMouse == false && !aboutInfo && !blankBG){
          if( i <= (frase.length-1) ){
            if(rotate < 359){
              window.c.save();
              window.c.translate(cv.width/2, cv.height/2);
              window.c.rotate(-(rotate*Math.PI/180));
              window.c.translate(-cv.width/2, -cv.height/2);
              window.c.fillStyle = "rgba(149, 165, 166,.8)";
              window.c.font = fontsize + "px Open Sans";
              window.c.fillText(frase[(frase.length-1) - i], cv.width/2, (cv.height/2)-radius);
              window.c.textAlign = "center";
              window.c.baseLine = "center";
              window.c.restore();
              rotate += rotateVal;
            }else{
              if(radius > 10){
                radius = radius + fontsize;
                rotateVal = 360/radius;
                rotate = 0;
              }
            }
            i++;
          }
        }else if(!followMouse && aboutInfo && blankBG ){
          clearInterval(createCircle);
          blankBGMake();
        }else if(!followMouse && aboutInfo && !blankBG){
          clearInterval(createCircle);
          aboutInfoMake();
        }else{
          clearInterval(createCircle);
          init(startWithThese);
          a();
        }
      }, 1);
    }
    let athis = this;
    var prevScrollpos = window.pageYOffset;
      window.addEventListener('scroll', function(){
      var currentScrollPos = window.pageYOffset;
      if (prevScrollpos < currentScrollPos) {
        athis.data.hideheader=true
      } else {
          athis.data.hideheader=false
      }
        prevScrollpos = currentScrollPos;
    })
    function aboutInfoMake(){
      var detectChanges = setInterval(function(){
        if( !followMouse && aboutInfo && !blankBG ){
          window.c.fillStyle = '#001628';
          window.c.fillRect(0,0,cv.width, cv.height);
        }else if(!followMouse && aboutInfo && blankBG ){
          clearInterval(detectChanges);
          blankBGMake();
        }else if( !followMouse && !aboutInfo && !blankBG ){
          clearInterval(detectChanges);
          window.c.fillStyle = 'rgba(249, 249, 249, 1)';
          window.c.fillRect(0,0,cv.width, cv.height);
          makeCircle();
        }else{
          clearInterval(detectChanges);
          init(startWithThese);
          a();
        }
      }, 1);
    }
    function blankBGMake(){
      var detectChanges = setInterval(function(){
        if(!followMouse && aboutInfo && blankBG ){
          
        }else if( !followMouse && aboutInfo && !blankBG ){
          clearInterval(detectChanges);
          aboutInfoMake();
        }else if( !followMouse && !aboutInfo && !blankBG ){
          clearInterval(detectChanges);
          window.c.fillStyle = 'rgba(249, 249, 249, 1)';
          window.c.fillRect(0,0,cv.width, cv.height);
          makeCircle();
        }else{
          clearInterval(detectChanges);
          init(startWithThese);
          a();
        }
      }, 1);
    }
  }
  //legal: boolean = false
  onclick_legal(){
    this.Dialog.open(LegalComponent, {
      maxWidth: "650px"
    })

    //Condition to close Legal Content when choose a menu option

    /* if (this.legal){
      document.querySelector('.navigation__menu').classList.remove('hide')
    } */
  }
}
