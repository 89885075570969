import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injectable, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-rounting.module';

import { AppComponent } from './app.component';
import { Page4Component } from './components/page4/page4.component';
import { Page3Component } from './components/page3/page3.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { AboutComponent } from './components/about/about.component';
import { LegalComponent } from './dialogs/legal/legal.component';
import { IbmcognosComponent } from './components/ibmcognos/ibmcognos.component';
import { DesignComponent } from './components/design/design.component';
import { EnterpriseApplicationComponent } from './components/enterprise-application/enterprise-application.component';
import { CodeingComponent } from './components/codeing/codeing.component';
import { DataService } from './services/data.service';
import { Page4dayComponent } from './components/page4day/page4day.component';
import { WhoDoWeWorkForComponent } from './components/who-do-we-work-for/who-do-we-work-for.component';
import { WhoDoWeWorkForDayComponent } from './components/who-do-we-work-for-day/who-do-we-work-for-day.component';
import { WhoWeAreComponent } from './components/who-we-are/who-we-are.component';
import { EmployeesService } from './components/employees/employees.service';
import { ContactComponent } from './components/contact/contact.component';
import { AmvaraClockComponent } from './components/amvara-clock/amvara-clock.component';
import { AmvaraVideoComponent } from './components/amvara-video/amvara-video.component'
import { EmployeeInformationComponent } from './components/employee-information/employee-information.component';
import { AboutNightComponent } from './components/about-night/about-night.component';
// import * as Hammer from 'hammerjs';
// import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';

import * as Sentry from "@sentry/browser";
import { EmployeePicturePipe } from './pipes/employee-picture.pipe';

Sentry.init({
  dsn: "https://a8fa112f53734f0bb3b9d76ede96d07a@sentry.amvara.de/6"
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    const eventId = Sentry.captureException(error.originalError || error);
    // Sentry.showReportDialog({ eventId });
  }
}

/* @Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    'swipe': { direction: Hammer.DIRECTION_VERTICAL }
  };
  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'pan-y',
    });
    return mc;
  }
} */


@NgModule({
    declarations: [
        AppComponent,
        HomepageComponent,
        AboutComponent,
        Page3Component,
        Page4Component,
        LegalComponent,
        IbmcognosComponent,
        DesignComponent,
        EnterpriseApplicationComponent,
        CodeingComponent,
        Page4dayComponent,
        WhoDoWeWorkForComponent,
        WhoDoWeWorkForDayComponent,
        WhoWeAreComponent,
        WhoWeAreComponent,
        ContactComponent,
        AmvaraClockComponent,
        AmvaraVideoComponent,
        EmployeeInformationComponent,
        AboutNightComponent,
        EmployeePicturePipe,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatDialogModule,
        MatButtonModule,
        MatExpansionModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        DataService,
        EmployeesService,
        { provide: ErrorHandler, useClass: SentryErrorHandler },
        /* {
          provide: HAMMER_GESTURE_CONFIG,
          useClass: MyHammerConfig
        } */
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
