import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Pipe({
  name: 'employeePicture'
})
export class EmployeePicturePipe implements PipeTransform {

  constructor(
    private _sanitizer: DomSanitizer
  ) { }

  transform(picture: string): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle(`url(/assets/img/profile/${picture})`);
  }

}
