import { Component, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { routerTransitionStars } from '../../app-router-animations';


@Component({
  selector: 'app-page4',
  templateUrl: './page4.component.html',
  styleUrls: ['./page4.component.sass'],
  animations: [routerTransitionStars],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class Page4Component {
  animationState: string;
  id: string;
  pages: Array<string> = ['enterprise-application','ibmcognos', 'codeing', 'design'];
  state: number;

  @ViewChild('o') outlet: RouterOutlet;

  getState(outlet) {
    this.state = outlet.activatedRouteData['stars'];
    return this.state;
  }
}
